import { GET, QUERY } from "@/core/services/store/request.module";

export default {
  data() {
    return {
      transaction_type: null,
      transaction_contact_person_dialog: false,
      transaction_property_dialog: false,
      transaction_billing: 0,
      transaction_customer: 0,
      transaction_contact_person: 0,
      transaction_property: 0,
    };
  },
  methods: {
    createCustomer() {},
    createContactPerson() {},
    createCustomerProperty() {},
    getJobCustomers(search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, {
              url: "customer-project-contract-list",
              data: { search: search },
            })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomers(search, entity) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(QUERY, { url: "customer-list", data: { search, entity } })
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerPersons(customer, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "contact-person-list/" + customer,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerProperties(customer, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "property-list/" + customer,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomerInvoices(customer, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "invoice-list/" + customer,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    getPaymentInvoices(customer, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "invoice-list-status/" + customer,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },

    getCustomerBilling(customer, search) {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          if (customer <= 0) {
            resolve([]);
          } else {
            _this.$store
              .dispatch(QUERY, {
                url: "billing-list/" + customer,
                data: { search: search },
              })
              .then((response) => {
                resolve(response.data);
              })
              .catch((error) => {
                reject(error);
              });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    getCustomer() {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.$store
            .dispatch(GET, { url: "customer/" + _this.customer })
            .then((response) => {
              let billingAddress = this.getBillingAddress(response.data);
              this.transaction_billing = billingAddress.id;
              resolve(response.data);
            })
            .catch((error) => {
              reject(error);
            })
            .finally(() => {
              _this.masterDialogClose();
            });
        } catch (error) {
          reject(error);
        }
      });
    },
    getDefaultPerson(customer) {
      let defaultPerson = {};
      const _this = this;
      if (customer && _this.lodash.isEmpty(customer.persons) === false) {
        for (let i = 0; i < customer.persons.length; i++) {
          if (customer.persons[i].default) {
            defaultPerson = _this.lodash.cloneDeep(customer.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPersonById(customer, id) {
      let defaultPerson = {};
      const _this = this;
      if (customer && _this.lodash.isEmpty(customer.persons) === false) {
        for (let i = customer.persons.length - 1; i >= 0; i--) {
          if (customer.persons[i].id == id) {
            defaultPerson = _this.lodash.cloneDeep(customer.persons[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(defaultPerson);
    },
    getPropertyById(customer, id) {
      let property = {};
      const _this = this;
      if (customer && _this.lodash.isEmpty(customer.address) === false) {
        for (let i = customer.address.length - 1; i >= 0; i--) {
          if (customer.address[i].id == id) {
            property = _this.lodash.cloneDeep(customer.address[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(property);
    },
    getBillingAddress(customer) {
      let billing = {};
      const _this = this;
      if (customer && _this.lodash.isEmpty(customer.address) === false) {
        for (let i = customer.address.length - 1; i >= 0; i--) {
          if (customer.address[i].type == 2) {
            billing = _this.lodash.cloneDeep(customer.address[i]);
            break;
          }
        }
      }
      return _this.lodash.cloneDeep(billing);
    },
    getEmailDetails(customer) {
      let emailArr = [];
      const _this = this;
      if (customer && _this.lodash.isEmpty(customer.details) === false) {
        for (let i = 0; i < customer.details.length; i++) {
          if (customer.details[i].type === 1) {
            emailArr.push(customer.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(emailArr);
    },
    getPhoneDetails(customer) {
      let phoneArr = [];
      const _this = this;
      if (customer && _this.lodash.isEmpty(customer.details) === false) {
        for (let i = 0; i < customer.details.length; i++) {
          if (customer.details[i].type === 2) {
            phoneArr.push(customer.details[i]);
          }
        }
      }
      return _this.lodash.cloneDeep(phoneArr);
    },
    resetAll() {
      this.transaction_customer = 0;
      this.transaction_contact_person = 0;
      this.transaction_property = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.transaction_contact_person_dialog = false;
      this.transaction_property_dialog = false;
    },
    selectCustomerPerson(person) {
      this.transaction_contact_person = this.lodash.toSafeInteger(person);
      this.transaction_contact_person_dialog = false;
      if (this.transaction_property <= 0) {
        this.transaction_property_dialog = true;
      }
      this.checkJob();
    },
    selectCustomerProperty(property) {
      this.transaction_property = this.lodash.toSafeInteger(property);
      this.checkJob();
    },
    checkJob() {
      const _this = this;
      let customer = _this.lodash.toSafeInteger(_this.transaction_customer);
      let contact_person = _this.lodash.toSafeInteger(
        _this.transaction_contact_person
      );
      let property = _this.lodash.toSafeInteger(_this.transaction_property);
      let billing = _this.lodash.toSafeInteger(_this.transaction_billing);

      if (_this.transaction_type === "quotation") {
        if (customer > 0 && contact_person > 0 && billing > 0) {
          _this.$router.push(
            _this.getDefaultRoute("quotation.create", {
              query: {
                customer: customer,
                contact_person: contact_person,
                billing: billing,
              },
            })
          );
        }
      } else if (_this.transaction_type === "visit") {
        if (customer > 0 && contact_person > 0 && property > 0) {
          _this.$router.push(
            _this.getDefaultRoute("job.create", {
              query: {
                customer,
                contact_person,
                property,
              },
            })
          );
        }
      } else {
        if (customer > 0 && contact_person > 0 && property > 0) {
          _this.$router.push(
            _this.getDefaultRoute(_this.transaction_type + ".create", {
              query: {
                customer,
                contact_person,
                property,
              },
            })
          );
        }
      }
    },
    createTransaction(customer, param) {
      const _this = this;
      _this.transaction_customer = _this.lodash.toSafeInteger(customer.id);
      _this.transaction_type = _this.lodash.toString(param);

      if (_this.transaction_type === "property") {
        _this.$router.push(
          _this.getDefaultRoute("property.create", {
            query: { customer: _this.transaction_customer },
          })
        );
      } else {
        if (customer.persons_count > 1) {
          _this.transaction_contact_person_dialog = true;
        } else if (customer.property_count > 1) {
          _this.transaction_property_dialog = true;
        }

        if (customer.persons_count == 1) {
          let firstPerson = _this.lodash.head(customer.persons);
          _this.transaction_contact_person = firstPerson.id;
        }

        if (customer.property_count == 1) {
          let firstProperty = _this.lodash.head(customer.property);
          _this.transaction_property = firstProperty.id;
        }

        _this.checkJob();
      }
    },
  },
};
