var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"commonDialog":_vm.personDialog,"dialogWidth":_vm.dialogWidth},scopedSlots:_vm._u([{key:"title",fn:function(){return [(_vm.doUpdate)?_c('span',[_vm._v("Update")]):_c('span',[_vm._v("Add")]),(_vm.isTenant)?_c('span',[_vm._v(" Tenant")]):_c('span',[_vm._v(" Contact Person")])]},proxy:true},{key:"body",fn:function(){return [_c('v-form',{ref:"personForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.appendContactPerson.apply(null, arguments)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[(_vm.personDialog)?_c('v-row',{staticClass:"mx-3"},[_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required",attrs:{"for":"y-salutation"}},[_vm._v("Salutation")]),_c('v-select',{attrs:{"id":"y-salutation","items":_vm.salutationList,"dense":"","filled":"","rules":[
              _vm.validateRules.required(_vm.contactPerson.title, 'salutation'),
            ],"item-text":"text","item-value":"value","item-color":"cyan","label":"Salutation","solo":"","flat":"","color":"cyan"},model:{value:(_vm.contactPerson.title),callback:function ($$v) {_vm.$set(_vm.contactPerson, "title", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactPerson.title"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required",attrs:{"for":"y-first_name"}},[_vm._v("First Name")]),_c('v-text-field',{staticClass:"required-field",attrs:{"id":"y-first_name","rules":[
              _vm.validateRules.required(_vm.contactPerson.first_name, 'first name'),
              _vm.validateRules.minLength(
                _vm.contactPerson.first_name,
                'first name',
                2
              ),
              _vm.validateRules.maxLength(
                _vm.contactPerson.first_name,
                'first name',
                100
              ),
            ],"dense":"","filled":"","label":"First Name","solo":"","flat":"","color":"cyan"},on:{"keyup":function($event){return _vm.appendDisplayName()}},model:{value:(_vm.contactPerson.first_name),callback:function ($$v) {_vm.$set(_vm.contactPerson, "first_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactPerson.first_name"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500",attrs:{"for":"y-last_name"}},[_vm._v("Last Name")]),_c('v-text-field',{attrs:{"id":"y-last_name","rules":[
              _vm.validateRules.minLength(
                _vm.contactPerson.last_name,
                'last name',
                2
              ),
              _vm.validateRules.maxLength(
                _vm.contactPerson.last_name,
                'last name',
                100
              ),
            ],"dense":"","filled":"","label":"Last Name","solo":"","flat":"","color":"cyan"},on:{"keyup":function($event){return _vm.appendDisplayName()}},model:{value:(_vm.contactPerson.last_name),callback:function ($$v) {_vm.$set(_vm.contactPerson, "last_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactPerson.last_name"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required",attrs:{"for":"y-display_name"}},[_vm._v("Display Name (Attn. On PDF)")]),_c('v-text-field',{staticClass:"required-field",attrs:{"id":"y-display_name","rules":[
              _vm.validateRules.required(
                _vm.contactPerson.display_name,
                'display name'
              ),
              _vm.validateRules.minLength(
                _vm.contactPerson.display_name,
                'display name',
                2
              ),
              _vm.validateRules.maxLength(
                _vm.contactPerson.display_name,
                'display name',
                100
              ),
            ],"dense":"","filled":"","label":"Display Name","solo":"","flat":"","color":"cyan"},model:{value:(_vm.contactPerson.display_name),callback:function ($$v) {_vm.$set(_vm.contactPerson, "display_name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactPerson.display_name"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required",attrs:{"for":"y-primary_phone"}},[_vm._v("Mobile Number")]),_c('PhoneTextField',{attrs:{"id":"y-primary_phone","required":"","phoneKey":1},model:{value:(_vm.contactPerson.primary_phone),callback:function ($$v) {_vm.$set(_vm.contactPerson, "primary_phone", $$v)},expression:"contactPerson.primary_phone"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500",attrs:{"for":"y-did"}},[_vm._v("DID ")]),_c('PhoneTextField',{attrs:{"id":"y-did","phoneKey":2},model:{value:(_vm.contactPerson.did),callback:function ($$v) {_vm.$set(_vm.contactPerson, "did", $$v)},expression:"contactPerson.did"}})],1),_c('v-col',{attrs:{"md":"4"}},[_c('label',{staticClass:"font-size-16 font-weight-500 required",attrs:{"for":"y-primary_email"}},[_vm._v("Email")]),_c('v-text-field',{staticClass:"required-field",attrs:{"id":"y-primary_email","rules":[
              _vm.validateRules.required(_vm.contactPerson.primary_email, 'email'),
              _vm.validateRules.validEmail(_vm.contactPerson.primary_email, 'email'),
              _vm.validateRules.minLength(
                _vm.contactPerson.primary_email,
                'email',
                2
              ),
              _vm.validateRules.maxLength(
                _vm.contactPerson.primary_email,
                'email',
                100
              ),
            ],"dense":"","filled":"","label":"Email","solo":"","flat":"","color":"cyan"},model:{value:(_vm.contactPerson.primary_email),callback:function ($$v) {_vm.$set(_vm.contactPerson, "primary_email", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"contactPerson.primary_email"}}),(false)?_c('v-combobox',{attrs:{"small-chips":"","id":"y-primary_email","items":_vm.contactPerson.emails,"label":"Emails","color":"cyan","multiple":"","dense":"","filled":"","solo":"","flat":"","item-color":"cyan","hide-details":"","rules":[_vm.validateRules.required(_vm.contactPerson.emails, 'emails')]},on:{"change":function($event){return _vm.validateTagEmail()}},scopedSlots:_vm._u([{key:"selection",fn:function({ attrs, item, index, selected }){return [(index === 0)?_c('v-chip',_vm._b({attrs:{"label":"","small":"","input-value":selected,"close":""},on:{"click:close":function($event){return _vm.removeEmail(item)}}},'v-chip',attrs,false),[_c('span',[_vm._v(_vm._s(item))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text font-size-12 mx-2"},[_vm._v(" (+"+_vm._s(_vm.contactPerson.emails.length - 1)+" others) ")]):_vm._e()]}}],null,false,3863465333),model:{value:(_vm.contactPerson.emails),callback:function ($$v) {_vm.$set(_vm.contactPerson, "emails", $$v)},expression:"contactPerson.emails"}}):_vm._e()],1)],1):_vm._e()],1)]},proxy:true},{key:"action",fn:function(){return [_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",on:{"click":function($event){return _vm.$emit('close', true)}}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"color":"cyan","disabled":!_vm.formValid},on:{"click":_vm.appendContactPerson}},[(_vm.doUpdate)?_c('span',[_vm._v("Update")]):_c('span',[_vm._v("Add")])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }